<template>
  <div>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Lixeira de Alunos
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Lixeira de Alunos
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__classic table-striped table-borderless table-hover text-center mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      Turma
                    </th>
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th scope="col" class="coluna-lixeira-restaurar" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredRows" :key="item.id">
                    <td class="text-left pl-4" v-html="item.turma.titulo" />
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.nome)"
                    />
                    <td class="text-left pl-4" v-html="item.usuario.email" />
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="restore(item.id)"
                      >
                        Restaurar
                      </button>
                      <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import Swal from "sweetalert2";
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";
import store from "@/store";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
  },
  data() {
    return {
      showLeftMenu: true,
      showInstituicao: true,
      id: null,
      index: -1,
      turmaSelecionadaEdit: 0,
      item: {
        id: null,
        nome: "",
        email: "",
        telefone: "",
        senha: "",
        turma: null,
        instituicaoSelecionada: "",
        anoSelecionado: "",
        turmaSelecionada: "",
      },
      items: [],
      filter: "",
      instituicoes: [],
      anos: [],
      turmas: [],
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (
        !this.item.nome ||
        (this.item.instituicaoSelecionada == "" &&
          checkUserHaveRole([
            constantes.ROLE_SUPER_ADMIN,
            constantes.ROLE_CLIENTE_ADMIN,
          ])) ||
        !this.item.email ||
        !this.item.telefone ||
        (!this.item.senha && this.index < 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();

    if (
      checkUserHaveRole([
        constantes.ROLE_SUPER_ADMIN,
        constantes.ROLE_CLIENTE_ADMIN,
      ])
    ) {
      this.loadInstituicoes();
    } else {
      this.showInstituicao = false;
      this.item.instituicaoSelecionada =
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO;
      this.onChangeInstituicao();
    }
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    deletar(index) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção do aluno(a)?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$root.$emit("Spinner::show");

            this.$api.delete("alunos/" + index).then(() => {
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Aluno(a) removido com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(function() {
                location.reload();
              }, 1000);
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      // this.items.splice(index, 1);
    },
    restore(id) {
      this.$api
        .put("alunos/" + id + "/recovery", {})
        .then((d) => {
          console.log(d);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Aluno(a) restaurado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.items.splice(index, 1);
          setTimeout(function() {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    loadInstituicoes() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("instituicao_ensinos")
        .then((response) => {
          this.instituicoes = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$root.$emit("Spinner::hide");
        });
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      let filterInstituicao = [];
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$api.get("alunos").then((response) => {
        if (idInstituicao == 0) {
        //   this.items = response.data;
          response.data.forEach((res) => {
               if (res.trashAt != null) filterInstituicao.push(res);
          })
        } else {
          response.data.forEach((res) => {
            if (res.instituicao.id == idInstituicao) {
              if (res.trashAt != null) filterInstituicao.push(res);
            }
          });
          this.items = filterInstituicao;
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");

      this.$api.get("ano_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anos.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    onChangeInstituicao() {
      this.loadAnos();
    },
  },
};
</script>

<style></style>
